import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import i18n from '@/libs/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select'
// import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleDot, far } from '@fortawesome/free-regular-svg-icons'
import {
  faUsers,
  faCalendar,
  faCheckCircle,
  faCakeCandles,
  fas,
  fa,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import store from './store'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);


Vue.component('model-list-select', ModelListSelect)

library.add(fas, faCakeCandles)
library.add(far, faCircleDot)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  datePickerShowDayPopover: false,
  locale: 'id-ID',
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['DD MMM YYYY'],
    dayPopover: 'D',
    data: ['YYYY-MM-DD'],
  },
})

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')
require('@core/scss/vue/libs/vue-flatpicker.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/scss/_custom.scss')
require('@/assets/scss/style.scss')
require('@fortawesome/fontawesome-free/css/all.css') // For form-wizard

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
