import Vue from 'vue'
import VueRouter from 'vue-router'
import _accessApi from '@/router/_accessApi.js'
import axios from 'axios'

const Login = () => import('@/views/authentication/Login.vue')
// const Page404 = () => import('@/views/error/Error404.vue')

// idp
const IdpChpass    = () => import('@/views/idp_chpass/Chpass.vue')
const IdpDashboard    = () => import('@/views/idp_dashboard/Dashboard.vue')
const IdpPoliceOnDuty    = () => import('@/views/idp_police_onduty/PoliceOnDuty.vue')

const IdpWarga        = () => import('@/views/idp_warga/Warga.vue')
const IdpWargaDetail  = () => import('@/views/idp_warga/WargaDetail.vue')

const IdpKendaraan        = () => import('@/views/idp_kendaraan/Kendaraan.vue')
const IdpKendaraanDetail  = () => import('@/views/idp_kendaraan/KendaraanDetail.vue')

const IdpPenanganan        = () => import('@/views/idp_penanganan/Penanganan.vue')
const IdpPenangananDetail  = () => import('@/views/idp_penanganan/PenangananDetail.vue')

const IdpJudgement  = () => import('@/views/idp_judgement/Judgement.vue')
const IdpRadio  = () => import('@/views/idp_radio/Radio.vue')

const IdpAbsensi  = () => import('@/views/idp_absensi/Absensi.vue')

const IdpDPO  = () => import('@/views/idp_dpo/Dpo.vue')
const IdpDPORequest  = () => import('@/views/idp_dpo/DpoRequest.vue')
const IdpDPORelease  = () => import('@/views/idp_dpo/DpoRelease.vue')

const IdpMasterRegistration  = () => import('@/views/idp_master/Registration.vue')
const IdpMasterUser  = () => import('@/views/idp_master/User.vue')
const IdpMasterNews  = () => import('@/views/idp_master/News.vue')
const IdpMasterPasal  = () => import('@/views/idp_master/Pasal.vue')
const IdpMasterKendaraanOut  = () => import('@/views/idp_master/KendaraanOut.vue')
const IdpSysLog  = () => import('@/views/idp_master/Syslog.vue')
const IdpAksesMenu  = () => import('@/views/idp_master/AksesMenu.vue')
const IdpManual  = () => import('@/views/idp_master/Manual.vue')
const IdpUserActive  = () => import('@/views/idp_master/UserActive.vue')
const IdpRoleConfig  = () => import('@/views/idp_master/RoleConfig.vue')
const DcWebhook  = () => import('@/views/idp_master/DcWebhook.vue')

Vue.use(VueRouter)

function configRoutes() {
  return [
    { path: '*', redirect: 'login' },
    {
      path: '/',
      redirect: { name: 'login' },
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'full',
      },
      component: Login,
    },

    {
      path: '/idp-chpass',
      name: 'idp-chpass',
      component: IdpChpass,
    },
    {
      path: '/idp-dashboard',
      name: 'idp-dashboard',
      component: IdpDashboard,
    },
    {
      path: '/idp-police-on-duty',
      name: 'idp-police-on-duty',
      component: IdpPoliceOnDuty,
    },
    {
      path: '/idp-warga',
      name: 'idp-warga',
      component: IdpWarga,
    },
    {
      path: '/idp-warga-detail/:id',
      name: 'idp-warga-detail',
      component: IdpWargaDetail,
    },

    {
      path: '/idp-kendaraan',
      name: 'idp-kendaraan',
      component: IdpKendaraan,
    },
    {
      path: '/idp-kendaraan-detail/:plate',
      name: 'idp-kendaraan-detail',
      component: IdpKendaraanDetail,
    },

    {
      path: '/idp-penanganan',
      name: 'idp-penanganan',
      component: IdpPenanganan,
    },
    {
      path: '/idp-penanganan-detail/:id',
      name: 'idp-penanganan-detail',
      component: IdpPenangananDetail,
    },

    {
      path: '/idp-judgement',
      name: 'idp-judgement',
      component: IdpJudgement,
    },
    {
      path: '/idp-radio',
      name: 'idp-radio',
      component: IdpRadio,
    },
    {
      path: '/idp-dpo',
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/idp-dpo",
          name: "idp-dpo",
          component: IdpDPO,
        },
        {
          path: "/request-dpo",
          name: "request-dpo",
          component: IdpDPORequest,
        },
        {
          path: "/release-dpo",
          name: "release-dpo",
          component: IdpDPORelease,
        },
      ]
    },

    {
      path: '/idp-absensi',
      name: 'idp-absensi',
      component: IdpAbsensi,
    },

    {
      path: '/master-user',
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/master-user",
          name: "master-user",
          component: IdpMasterUser,
        },
        {
          path: "/master-registration",
          name: "master-registration",
          component: IdpMasterRegistration,
        },
        {
          path: "/master-news",
          name: "master-news",
          component: IdpMasterNews,
        },
        {
          path: "/master-pasal",
          name: "master-pasal",
          component: IdpMasterPasal,
        },
        {
          path: "/master-kendaraan-out",
          name: "master-kendaraan-out",
          component: IdpMasterKendaraanOut,
        },
        {
          path: "/syslog",
          name: "syslog",
          component: IdpSysLog,
        },
        {
          path: "/access-menu",
          name: "access-menu",
          component: IdpAksesMenu,
        },
        {
          path: "/manual-trigger",
          name: "manual-trigger",
          component: IdpManual,
        },
        {
          path: "/user-active",
          name: "user-active",
          component: IdpUserActive,
        },
        {
          path: "/role-config",
          name: "role-config",
          component: IdpRoleConfig,
        },
        {
          path: "/dc-webhook",
          name: "dc-webhook",
          component: DcWebhook,
        },
      ]
    },
  ]
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: configRoutes(),
})

if (location.host === 'localhost:8080' || location.host === 'localhost:8081') {
  axios.defaults.baseURL = _accessApi.localhost.url
  axios.defaults.upload_path = _accessApi.localhost.upload_path
  axios.defaults.download_assets_url = _accessApi.localhost.download_assets_url
} else {
  axios.defaults.baseURL = _accessApi.server.url
  axios.defaults.upload_path = _accessApi.server.upload_path
  axios.defaults.download_assets_url = _accessApi.server.download_assets_url
}

router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('token')
  const publicPages = ['login']
  const extract = to.path.split('/')
  const authRequired = !publicPages.includes(extract[1])

  if (authRequired && token === null) {
    // alert('Not Valid User!')
    next('/login')
  } else if (extract[1] === 'login') {
    next()
  } else {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    axios.post('/authentication/restricted', { path: to.path, path_name: to.name }).then(response => {
      if (response.data.status === 2000) {
        next()
      } else {
          // localStorage.clear()
          // next('/login')
          // alert("No Access!!");
      }
    }).catch(error => {
      next('/login')
    })
  }
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router